<template>
  <v-container
    fluid
    class="pa-0 ma-0"
    :style="marginTop"
  >
    <div class="pb-0 pb-sm-16 cs-body-bg">
      <div class="mx-auto my-0 px-0 py-0" :style="maxWidth">
        <title-block :title="detail.title" />
        <v-row no-gutters>
          <v-col class="col-12 col-sm-9 align-self-stretch">
            <template v-if="playing">
              <embed-player :key="player_key" :width="embed_width" :height="embed_height" :detail="detail" />
            </template>
            <template v-else>
              <v-img
                max-width="100%"
                src="/images/cover-0.jpg?v=7"
              >
                <div class="mx-auto d-flex white--text fill-height justify-center align-center" style="background-color: rgba(0,0,0,0.35);">
                  <span class="text-h3">敬请期待</span>
                </div>
              </v-img>
            </template>
            <div v-if="true" class="d-flex d-sm-none justify-center my-6">
              <v-btn
                width="180"
                color="#01BDCB"
                class="white--text text-subtitle-1 py-5"
                @click="intoWebinar"
              >
                {{ into_webinar }}
              </v-btn>
            </div>
          </v-col>
          <v-col class="col-12 col-sm-3 pl-sm-6 align-self-stretch">
            <div class="d-block white px-sm-3 py-sm-3 px-3 px-sm-0 fill-height">
              <div class="text-h5 py-2 py-sm-0 px-0">论坛列表</div>
              <template v-for="(item, index) of lives">
                <div
                  :key="`${item.branch}-${index}`"
                  class="d-flex align-center px-2 py-2 cs-discuz"
                  :class="[{'cs-discuz-active': current_branch===item.branch}]"
                  @click="setBranch(item.branch)"
                >
                  <v-img
                    max-width="60"
                    :src="`${item.cover}?v=7`"
                  />
                  <div class="pl-1 pl-sm-2">
                    <div class="text-body-2 font-weight-bold">{{ item.title }}</div>
                    <div class="text-body-2">{{ item.start_time }}</div>
                  </div>
                </div>
                <v-divider v-if="index !== (lives.length - 1)" :key="`divider-${item.branch}-${index}`" class="my-2 my-sm-0" />
              </template>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="true" no-gutters class="pt-4 pt-sm-16 d-none d-sm-flex">
          <v-col class="col-12 col-sm-9 text-center">
            <v-btn
              width="180"
              color="#01BDCB"
              class="white--text text-subtitle-1 py-5"
              @click="intoWebinar"
            >
              {{ into_webinar }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <title-block title="大会议程" />
      <agenda-block :items="items" />
    </div>
    <bottom-banner />
    <v-bottom-sheet
      v-model="sheet"
    >
      <v-sheet class="text-center">
        <div class="py-6 py-sm-12" @click="sheet=false">
          <div class="text-body-1 text-sm-h5 font-weight-bold green--text text--accent-4 mb-2 mb-sm-4">切换成功！</div>
          <div>当前论坛：<span class="font-weight-bold">{{ detail.title }}</span></div>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
const PLAYER_WIDTH = 900
const PLAYER_HEIGHT = 512
import { mapGetters } from 'vuex'
import { EVENTCENTER, DATACENTER } from '@/datacenter'
import  { SYMPOSIA_MEETUP } from '@/constants'
import  { getTokenByEventIdForCookie } from '@/util/helpers.js'
import TitleBlock from '@/views/components/TitleBlock'
import AgendaBlock from '@/views/components/AgendaBlock'
import BottomBanner from '@/views/components/BottomBanner'
import Tools from '@/mixins/Tools.js'
import EmbedPlayer from '@/components/EmbedPlayer.vue'
export default {
  name: 'Liveroom',
  mixins: [Tools],
  components: {
    TitleBlock,
    AgendaBlock,
    BottomBanner,
    EmbedPlayer
  },
  data() {
    return {
      current_branch: '',
      items: [],
      lives: [],
      sheet: false,
      playing: true,
      player_key: 'player-key',
      auto_mounted: true,
      into_webinar: '进入直播间互动',
      embed_width: PLAYER_WIDTH,
      embed_height: PLAYER_HEIGHT
    }
  },
  computed: {
    ...mapGetters(['event_id']),
    detail() {
      if (this.lives.length > 0) {
        return this.lives.find((item) => {
          return item.branch === this.current_branch
        })
      }
      return {}
    }
  },
  mounted() {
    this.getLiveDetail(this.event_id)
    this.handleMobile(this.is_mobile)
    this.setBranch('main')
  },
  watch: {
    current_branch(newV) {
      this.getLiveAgenda(this.event_id, newV)
      if (this.auto_mounted) {
        this.auto_mounted = false
      } else {
        this.sheet = true
      }
      this.player_key = `player-key-${ new Date().getTime() }`
    },
    event_id(newV) {
      this.getLiveDetail(newV)
      this.getLiveAgenda(newV, this.current_branch)
    },
    is_mobile(newV) {
      this.handleMobile(newV)
    }
  },
  methods: {
    getLiveDetail(event_id) {
      this.lives = EVENTCENTER.filter((item) => {
        return item.event_id === event_id
      })
    },
    getLiveAgenda(event_id, branch) {
      const tmp_datetime = DATACENTER.filter((item) => {
        return item.event_id === event_id && item.branch === branch && item.time
      })
      this.items = tmp_datetime.sort((a, b) => a.title_order - b.title_order)
    },
    // 设置分论坛并提示成功消息
    setBranch(branch) {
      this.current_branch = branch
    },
    intoWebinar() {
      let token = getTokenByEventIdForCookie(SYMPOSIA_MEETUP, this.event_id)
      const tmp_url = `${this.detail.live_url}` + (token? `?t=${token}` : '')
      // 跳转进入直播间交流
      document.location.href = tmp_url
    },
    handleMobile(bool) {
      if (bool) {
        this.embed_width = window.screen.width
        this.embed_height = window.screen.width * 9/16
      } else {
        this.embed_width = PLAYER_WIDTH
        this.embed_height = PLAYER_HEIGHT
      }
      this.player_key = `player-key-${new Date().getTime() }`
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-body-bg
  background-color: #F6F6F9 !important
.cs-discuz
  cursor: pointer
  &:hover
    background-color: #F5F5F5
.cs-discuz-active
  background-color: #F5F5F5
</style>
