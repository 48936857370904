<template>
  <iframe :src='`${detail.url}`' frameborder='0' :width='width' :height='height' scrolling='no' allowfullscreen='true' :style='styObj'></iframe>
</template>
<script>
export default {
  name: 'EmbedPlayer',
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  computed: {
    styObj() {
      return {
        display: 'block',
        width: this.width + 'px',
        height: this.height + 'px'
      }
    }
  }
}
</script>
